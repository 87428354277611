import { HttpSecureClient } from 'path-shared/services/http/httpSecureClient';
import { Injectable } from '@angular/core';
import { AuthGuardService } from 'path-shared/services/authentication/auth-guard.service';
import { Zona } from '../models/zona.model';
import { Observable } from 'rxjs';
import { config } from 'path-shared/configuration/config';
import { api } from 'path-shared/configuration/urls';
import { ZonesFilterService } from './zones-filter-service';
import { ZonesFilter } from '../models/zona-filter';


const defaultSortParam = 'codi';
const defaultSortDirection = 'desc';


@Injectable()
export class ZonesService {
  displayedItems = [];


  constructor(private http: HttpSecureClient, private authGuard: AuthGuardService) {
    this.displayedItems['checkedValues'] = 0;
    this.displayedItems['checkedItems'] = Array();
  }

  getAll(): Observable<any> {
    if (this.authGuard.canActivate()) {
      const params = new ZonesFilterService().createFilterParams(new ZonesFilter('nomZona', 'asc'), 0, 99999, 'nomZona', 'asc');
      return this.http.get(config.urls['apiUrl'] + api.endpoints['urlGetZones'],  params);
    }
  }

  getZonasDeltaCar(): Observable<any> {
    if (this.authGuard.canActivate()) {
      return this.http.get(config.urls['apiUrl'] + api.endpoints['urlGetZonesDeltaCar'],  null);
    }
  }


  removeZona(id): Observable<any> {
    if (this.authGuard.canActivate()) {
      return this.http.delete(config.urls['apiUrl'] + api.endpoints['urlDeleteZona'].replace('{secure_id}', id), null);
    }
  }

  getZona(id): Observable<any> {
    if (this.authGuard.canActivate()) {
      return this.http.get(config.urls['apiUrl'] + api.endpoints['urlGetZona'].replace('{secure_id}', id), null);
    }
  }
}
