<!-- HEADER -->
<app-dialog-header label="Aparell" i18n-label></app-dialog-header>
<!-- TOOLBAR -->
<app-toolbar>
    <app-tool-button text="Editar" i18n-text (click)="this.dialogData.isReadOnly = false" color="has-color-green"
        icon="fa-edit" [disabled]="!this.auth.UserCanWrite(this.auth.Pages.Aparells) || !this.dialogData.isReadOnly"
        [disabled]="!this.dialogData.isReadOnly"></app-tool-button>
    <app-tool-button text="Guardar" i18n-text (click)="this.save()" color="has-color-green" icon="fa-save"
        [disabled]="!this.auth.UserCanWrite(this.auth.Pages.Aparells) || this.dialogData.isReadOnly"></app-tool-button>
    <app-tool-button text="Certificats" i18n-text (click)="this.openCertificatsGrid()" color="has-color-blue"
        icon="fa-th-list"></app-tool-button>
    <app-tool-button text="Eliminar" i18n-text (click)="this.remove();" color="has-color-red" icon="fa-trash-alt"
        [disabled]="!this.auth.UserCanDelete(this.auth.Pages.Aparells) || this.dialogData.isReadOnly"></app-tool-button>
    <app-tool-button [text]="this.closeButtonText" i18n-text (click)="this.cancel()" color="has-color-yellow"
        icon="fa-th-list"></app-tool-button>
</app-toolbar>


<div class="card col">
    <form #mainForm>
        <!-- CONFIGURACIÓN BÁSICA -->
        <h5 i18n-label label="Configuración básica" style="margin-top: 1vh;"></h5>
        <div class="row">
            <app-form-text-edit id="aparells-editor-descripcio" class="col-4" i18n-label label="Descripció"
                [(value)]="this.aparell.descripcion" required maxLength="50"></app-form-text-edit>
            <app-form-combo-edit class="col-2" i18n-label label="Tipus" [items]="this.aparellsTipus"
                [(value)]="this.aparell.idTipus" (valueChange)="this.tipusRadarSelected()" valueField="id"
                textField="descTipus" required></app-form-combo-edit>
            <app-form-text-edit id="aparells-editor-numeroSerie" class="col-2" i18n-label label="Número sèrie"
                [(value)]="this.aparell.numeroSerie" required maxLength="12"></app-form-text-edit>
            <app-form-date-edit id="aparells-editor-dataRevisio" class="col-2" i18n-label label="Data revisió"
                [(value)]="this.aparell.fechaRevision" type="calendar"></app-form-date-edit>
            <app-form-date-edit id="aparells-editor-dataCaducitat" class="col-2" i18n-label label="Data caducitat"
                [(value)]="this.aparell.fechaCaducidad" type="calendar" required></app-form-date-edit>
        </div>
        <div class="row">
            <app-form-text-edit id="aparells-editor-marca" class="col-2" i18n-label label="Marca"
                [(value)]="this.aparell.marca" required maxLength="30"></app-form-text-edit>
            <app-form-text-edit id="aparells-editor-model" class="col-2" i18n-label label="Model"
                [(value)]="this.aparell.modelo" required maxLength="30"></app-form-text-edit>
            <app-form-multi-select class="col-6" style="margin-top:1vh; margin-bottom:2vh;" titulo="Llistes Blanques"
                [data]="this.llistesBlanques" [(selectedValues)]="this.aparell.llistesBlanques" i18n-titulo
                tituloMultiSelect="Selecciona una o més" i18n-tituloMultiSelect></app-form-multi-select>
            <app-form-check-edit class="col-1" i18n-label label="Alarma habilitada"
                [(value)]="this.aparell.alarmaHabilitada"></app-form-check-edit>
        </div>
        <!-- CONFIGURACIONES ESPECÍFICAS -->
        <div *ngIf="this.aparell.idTipus === 5">
            <hr>
            <h5 i18n>Configuración específica: Delta Car</h5>
        </div>
        <div *ngIf="this.aparell.idTipus === 5" class="row">
            <div class="row" style="display: flex-inline; flex-flow: row; align-content: space-between;">
                <app-form-combo-edit class="col-2" i18n-label label="Tipus de sanció"
                    [items]="this.deltaCarTipus" [(value)]="this.aparell.deltaCarTipus" valueField="id"
                    textField="descripcio" required>
                </app-form-combo-edit>

                <!--Canviem l'enum de mètodes segons el tipus (ZB o POL), aprofitem la variable deltaCarZBMetode a la resta del codi encara que sigui POL-->
                <app-form-combo-edit *ngIf="this.aparell.deltaCarTipus === 'ZB'" class="col-2" i18n-label label="Mètode"
                    [items]="this.deltaCarZBMetodes" [(value)]="this.aparell.deltaCarZBMetode" valueField="id"
                    textField="descripcio" required></app-form-combo-edit>
                <app-form-combo-edit *ngIf="this.aparell.deltaCarTipus === 'POL'" class="col-2" i18n-label label="Mètode"
                    [items]="this.deltaCarPOLMetodes" [(value)]="this.aparell.deltaCarZBMetode" valueField="id"
                    textField="descripcio" required></app-form-combo-edit>

                <app-form-combo-edit class="col-2" *ngIf="!(this.aparell.deltaCarZBMetode === this.enumMetodeDeltaCar.PDA)" style="visibility: hidden;" disabled></app-form-combo-edit>
                <app-form-combo-edit class="col-2"
                    *ngIf="this.aparell.deltaCarZBMetode === this.enumMetodeDeltaCar.PDA"
                    i18n-label label="PDA" [items]="this.PDAs" [(value)]="this.aparell.DeltaCarPDA" valueField="id"
                    textField="id" required></app-form-combo-edit>

                <div class="col-6 delta-car-check-boxes">
                    <div class="row">
                        <mat-checkbox [checked]="this.aparell.doblePassada === true"
                            (change)="this.aparell.doblePassada = $event.checked ? true : false">
                            Doble passada
                        </mat-checkbox>

                        <app-form-text-edit *ngIf="!this.aparell.doblePassada" style="visibility: hidden;" disabled></app-form-text-edit>
                        <app-form-text-edit required type="number" *ngIf="this.aparell.doblePassada" i18n-label 
                            label="Minuts passada" [(value)]="this.aparell.minutsPassada"></app-form-text-edit>
                    </div>
                    <app-form-check-edit i18n-label class="row"
                        label="Modo solo detección"  [(value)]="this.aparell.modoSoloDeteccion">
                    </app-form-check-edit>
                </div>
            </div>
        </div>

        <div *ngIf="this.aparell.idTipus === 4 || this.aparell.idTipus === 3 ">
            <hr>
            <h5 i18n>Configuración específica: Márgenes de error</h5>

            <div class="row">
                <div class="col-4 margenes-error" style="margin-top: 1.5vh;">
                    <div class="row" style="margin-left: 1vh; margin-top: 1vh;">
                        <span class="text">v <= </span>
                                <app-form-text-edit id="aparells-editor-margePercentatge" type="number" class="col-4"
                                    i18n-label label="Velocidad" i18n-tooltip tooltip="Velocidad límite"
                                    [(value)]="this.aparell.marge"></app-form-text-edit>
                                <span class="text">±</span>
                                <app-form-text-edit id="aparells-editor-reduccio" type="number" class="col-4" i18n-label
                                    label="Margen" i18n-tooltip tooltip="Margen de error en kilómetros"
                                    [(value)]="this.aparell.reduccio"></app-form-text-edit>
                                <span class="text">km/h</span>

                    </div>
                    <div class="row" style="margin-left: 1vh;">
                        <span class="text">v ></span>
                        <app-form-text-edit id="aparells-editor-margePercentatge" type="number" class="col-4" i18n-label
                            label="Velocidad" [(value)]="this.aparell.marge" i18n-tooltip tooltip="Velocidad límite">
                        </app-form-text-edit>
                        <span class="text">±</span>
                        <app-form-text-edit id="aparells-editor-percentatgeErrorNom" type="number" class="col-4"
                            i18n-label label="Margen" i18n-tooltip tooltip="Margen de error en porcentaje"
                            [(value)]="this.aparell.margenErrorNombre" number maxLength="11"></app-form-text-edit>
                        <span class="text">%</span>
                    </div>
                </div>
                <div class="col-8 margenes-error-info">
                    <i class="fas fa-info-circle" style="color: cornflowerblue;"></i>
                    &nbsp; <b>Ayuda</b><br>
                    Desde este apartado puedes modificar los márgenes de error de un equipo. <br>
                    El campo velocidad indica la velocidad límite por la cual el sistema se rige para elegir método de
                    reducción. <br>
                    Por ejemplo, si se indica 100, todas las capturas con velocidad igual o inferior a 100 se les
                    restará el margen en kilómetros. <br>
                    Mientras que las capturas con velocidad superior a 100 se les restará el margen en porcentage <br>
                    <br>
                    Si se desea que solamente se aplice reducción en kilómetros, indique una velocidad muy alta
                    (p.e:1000) <br>
                    Si se desea que solamente se aplice reducción en porcentaje, indique una velocidad nula (deja el
                    campo vacío o un 0) <br>
                    Si no desea que se aplique ningun margen de error, indique unos márgenes nulos (deja el campo vacío
                    o un 0) <br>

                </div>
            </div>

        </div>

    </form>

    <!-- CONFIGURACIONES AVANZADAS -->
    <div *ngIf="mostrarConfiguracionAvanzada()">
        <hr>
        <h5 i18n>Configuración avanzada</h5>
    </div>
    <div *ngIf="(!isNull(this.aparell.idTipus) && this.aparell.idTipus !== 6 && this.aparell.idTipus !== 7)">
        <div *ngIf="(this.aparell.deltaCarTipus === 'ZB') && 
                this.aparell.deltaCarZBMetode === this.enumMetodeDeltaCar.CALLE" 
                class="row" style="display: flex-inline; flex-flow: row; align-content: space-between;">
            <app-form-combo-edit class="col-2" i18n-label label="PDA" 
                    [items]="this.PDAs" [(value)]="this.aparell.DeltaCarPDA" valueField="id" textField="id" required></app-form-combo-edit>
            <div class="container col-2">
                <h2 i18n>Carrers</h2>
                <mat-list>
                    <mat-list-item *ngFor="let calle of this.callesSinPDA">
                        <button mat-mini-fab color="blue" style="margin-right: 20px;" 
                                [disabled]="this.aparell.DeltaCarPDA == '' || this.aparell.DeltaCarPDA == null" 
                                (click)="this.assignarPdaToCalle(calle)">
                            <mat-icon>add</mat-icon>
                        </button>
                        {{calle.item_text_2}} {{calle.item_text}}
                    </mat-list-item>
                </mat-list>
            </div>
        </div>
        <div *ngIf="this.aparell.deltaCarZBMetode === this.enumMetodeDeltaCar.INFRACCION" 
                class="row" style="display: flex-inline; flex-flow: row; align-content: space-around;">
            <app-form-combo-edit class="col-2" i18n-label label="PDA" 
                    [items]="this.PDAs" [(value)]="this.aparell.DeltaCarPDA" valueField="id" textField="id" required></app-form-combo-edit>
            <div class="col-2">
                <h2 i18n>Infraccions</h2>
                <mat-list style="width: 500%;">
                    <mat-list-item *ngFor="let infraccion of this.infraccionesSinPDA" matLine>
                        <button mat-mini-fab color="" style="margin-right: 20px;" 
                                [disabled]="this.aparell.DeltaCarPDA == '' || this.aparell.DeltaCarPDA == null" 
                                (click)="this.assignarPdaToInfraccio(infraccion)">
                            <mat-icon>add</mat-icon>
                        </button>
                        <mat-label [maxlength]="100" [matLineClamp]="2" [matTooltip]="infraccion.item_text" matTooltipPosition="above">
                            {{ infraccion.item_text }}
                        </mat-label>
                    </mat-list-item>
                </mat-list>
            </div>
        </div>
        <div *ngIf="(this.aparell.deltaCarTipus === 'ZB') && 
                this.aparell.deltaCarZBMetode === this.enumMetodeDeltaCar.ZONA" 
                class="row" style="display: flex-inline; flex-flow: row; align-content: space-between;">
            <app-form-combo-edit class="col-2" i18n-label label="PDA" 
                    [items]="this.PDAs" [(value)]="this.aparell.DeltaCarPDA" valueField="id" textField="id" required></app-form-combo-edit>
            <div class="container col-2">
                <h2 i18n>Zones</h2>
                <mat-list>
                     <mat-list-item *ngFor="let zona of this.zonasSinPDA">
                        <button mat-mini-fab color="blue" style="margin-right: 20px;" 
                                [disabled]="this.aparell.DeltaCarPDA == '' || this.aparell.DeltaCarPDA == null" 
                                (click)="this.assignarPdaToZona(zona)">
                            <mat-icon>add</mat-icon>
                        </button>
                        {{zona.item_text}}
                    </mat-list-item> 
                </mat-list>
            </div>
        </div>
    </div>

    <mat-tab-group *ngIf="!isNull(this.aparell.idTipus) && this.aparell.idTipus !== 6 && this.aparell.idTipus !== 7">
        <mat-tab *ngIf="mostrarPanel" i18n-label label="Secció de localitzacions">
            <div class="tabla" style="overflow: auto;">
                <!-- <div *ngIf="sinData" class="alert alert-danger">
                        <span i18n>  No hi ha localitzacions per mostrar</span>
                    </div> -->

                <mat-table #table [dataSource]="dataSource" aria-label="Elements" class="is-header-sticky">
                    <!-- Rango Fechas -->
                    <ng-container matColumnDef="rangoFechas">
                        <mat-header-cell *matHeaderCellDef i18n style="width:30vh;">Rang de dates</mat-header-cell>
                        <mat-cell *matCellDef="let row">
                            <app-form-date-range-edit i18n-label1 label1="Data inici" i18n-label2 label2="Data fi"
                                [(value)]="row.rango_fechas" (valueChange)="setActiu($event, row)" required>
                            </app-form-date-range-edit>
                        </mat-cell>
                    </ng-container>

                    <!-- Verlocitat Via -->
                    <ng-container matColumnDef="velocitatVia">
                        <mat-header-cell *matHeaderCellDef i18n style="width:11vh;">Velocitat via</mat-header-cell>
                        <mat-cell *matCellDef="let row" class="text-center">
                            <app-form-text-edit id="aparells-editor-velVia" class="col-1" i18n-label
                                label="Velocitat via" [(value)]="row.vel_via" required maxLength="11" autocomplete="off"
                                type="number"></app-form-text-edit>
                        </mat-cell>
                    </ng-container>

                    <!-- Proposta -->
                    <ng-container matColumnDef="proposta">
                        <mat-header-cell *matHeaderCellDef i18n style="width:15vh;">Proposta</mat-header-cell>
                        <mat-cell *matCellDef="let row">
                            <app-form-combo-edit i18n-label label="Proposta" [items]="this.localizacionPropuestas"
                                [(value)]="row.propuesta" valueField="id" textField="descTipus" required>
                            </app-form-combo-edit>
                        </mat-cell>
                    </ng-container>

                    <!-- Infracció -->
                    <ng-container matColumnDef="infraccio">
                        <mat-header-cell *matHeaderCellDef i18n>Infracció</mat-header-cell>
                        <mat-cell *matCellDef="let row" class="td">
                            <div class="col-12" style="padding:unset">
                                <app-form-text-edit i18n-placeholder label="Infracció" i18n-label
                                    [(value)]="row.infraccionDesc" [isReadOnly]="this.isReadOnly" required
                                    [tooltip]="row.infraccionDesc"></app-form-text-edit>
                                <app-view-list-icon (listClick)="seleccionarInfraccion(row)"
                                    (viewClick)="seleccionarInfraccion(row)" [hideList]="this.isReadOnly">
                                </app-view-list-icon>
                            </div>
                        </mat-cell>
                    </ng-container>

                    <!-- Nom carrer -->
                    <ng-container matColumnDef="nomCarrer">
                        <mat-header-cell *matHeaderCellDef i18n>Nom carrer</mat-header-cell>
                        <mat-cell *matCellDef="let row" class="center">
                            <div class="col-12" style="padding:unset">
                                <app-form-text-edit i18n-label label="Carrer" [(value)]="this.row.calleDesc"
                                    [isReadOnly]="this.isReadOnly" required [tooltip]="this.row.calleDesc">
                                </app-form-text-edit>
                                <app-view-list-icon (listClick)="seleccionarCarrer(row)"
                                    (viewClick)="seleccionarCarrer(row)" [hideList]="this.isReadOnly">
                                </app-view-list-icon>
                            </div>

                        </mat-cell>
                    </ng-container>
                    <!-- Num Carrer -->
                    <ng-container matColumnDef="numCarrer">
                        <mat-header-cell *matHeaderCellDef i18n style="width:10vh">Número</mat-header-cell>
                        <mat-cell *matCellDef="let row" class="center">
                            <app-form-text-edit id="aparells-editor-numCalle" type="number" class="col-1" i18n-label
                                label="Num" [(value)]="row.numCalle" required autocomplete="off"></app-form-text-edit>
                        </mat-cell>
                    </ng-container>

                    <!-- Aparell Actiu -->
                    <ng-container matColumnDef="actiu">
                        <mat-header-cell *matHeaderCellDef i18n>Actiu</mat-header-cell>
                        <!-- {{ row.actiu }} -->
                        <mat-cell *matCellDef="let row" class="center">
                            <mat-checkbox (click)="seleccionarActiu(row)" [checked]="row.actiu"></mat-checkbox>
                        </mat-cell>
                    </ng-container>

                    <!-- Accions -->
                    <ng-container matColumnDef="accions">
                        <mat-header-cell *matHeaderCellDef i18n>Accions</mat-header-cell>
                        <mat-cell *matCellDef="let row; index as i">
                            <i (click)="updateLocalizacion(row, i)"
                                class="cursor fa fa-save btn btn-outline-primary btn-sm"></i>
                            <i (click)="removeLocalizacion(row.SOM_ID)"
                                class="cursor fa fa-trash-alt btn btn-outline-danger btn-sm"></i>
                        </mat-cell>
                    </ng-container>

                    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                    <mat-row name="rowSubmit" *matRowDef="let row; columns: displayedColumns;"></mat-row>

                </mat-table>
            </div>
        </mat-tab>

        <mat-tab *ngIf="this.aparell.deltaCarZBMetode !== null || 
            this.aparell.deltaCarZBMetode !== '' || 
            this.aparell.deltaCarZBMetode !== 'PDA'" 
            i18n-label [label]="getLabelAdvancedSettings()">
            <div *ngIf="this.isDeltaCar">
                <!-- <div class="tabla table-responsive"> -->
                <div *ngIf="!TieneDataDeltaCar() && 
                    (this.aparell.deltaCarZBMetode === this.enumMetodeDeltaCar.CALLE || 
                    this.aparell.deltaCarZBMetode === this.enumMetodeDeltaCar.INFRACCION ||
                    this.aparell.deltaCarZBMetode === this.enumMetodeDeltaCar.ZONA)"
                    class="alert alert-warning">
                    <i class='fas fa-exclamation-triangle'></i>&nbsp; <b i18n>Atenció</b><br>

                <span i18n>No hi ha infraccions per mostrar.</span><br>
                <span i18n>L'usuari de backoffice només pot modificar les infraccions, per a crear-ne de noves contacteu
                    amb informàtica.</span><br>
            </div>
            <div style="max-height: 42vh; overflow-y: auto !important;">
                <mat-table *ngIf="TieneDataDeltaCar()" #table2 [dataSource]="dataSourceDeltaCar()" aria-label="Elements"
                    class="is-header-sticky">

                        <!-- Infracció -->
                        <ng-container *ngIf="this.aparell.deltaCarZBMetode === enumMetodeDeltaCar.INFRACCION" 
                        matColumnDef="infraccio" style="width: 80%;">
                            <div>
                                <mat-header-cell *matHeaderCellDef>
                                    <span i18n>Infracción</span>
                                </mat-header-cell>
                                <mat-cell *matCellDef="let row" class="td">
                                    <span>{{ row.infraccioDesc }}</span>
                                </mat-cell>
                            </div>
                        </ng-container>

                        <!-- Carrer -->
                        <ng-container *ngIf="this.aparell.deltaCarZBMetode === enumMetodeDeltaCar.CALLE" 
                        matColumnDef="carrer" style="width: 80%;">
                            <div>
                                <mat-header-cell *matHeaderCellDef>
                                    <span i18n>Calle</span>
                                </mat-header-cell>
                                <mat-cell *matCellDef="let row" class="td">
                                    <span>{{row.carrerSiglas}} {{row.carrerDesc}}</span>
                                </mat-cell>
                            </div>
                        </ng-container>

                        <!-- Zona -->
                        <ng-container *ngIf="this.aparell.deltaCarZBMetode === enumMetodeDeltaCar.ZONA" 
                        matColumnDef="zona" style="width: 80%;">
                            <div>
                                <mat-header-cell *matHeaderCellDef>
                                    <span i18n>Zona</span>
                                </mat-header-cell>
                                <mat-cell *matCellDef="let row" class="td">
                                    <span>{{row.zonaDesc}}</span>
                                </mat-cell>
                            </div>
                        </ng-container>

                        <!-- PDA -->
                        <ng-container matColumnDef="PDA">
                            <mat-header-cell *matHeaderCellDef i18n style="width: 20%;">PDA</mat-header-cell>
                            <mat-cell *matCellDef="let row" class="center">
                                <span>{{ row.PDA }}</span>
                            </mat-cell>

                        </ng-container>

                        <!-- Accions -->
                        <ng-container matColumnDef="accions">
                            <mat-header-cell *matHeaderCellDef i18n style="width: 10%;">Accions</mat-header-cell>
                            <ng-container *ngIf="this.aparell.deltaCarZBMetode !== enumMetodeDeltaCar.CALLE 
                                                && this.aparell.deltaCarZBMetode !== enumMetodeDeltaCar.INFRACCION 
                                                && this.aparell.deltaCarZBMetode != enumMetodeDeltaCar.ZONA">
                                <mat-cell *matCellDef="let row">
                                    <!-- <i (click)="removeRTAI(row)" class="cursor fa fa-trash-alt btn btn-outline-danger btn-sm"></i> -->
                                    <button [disabled]="row.PDA===''" class="btn btn-outline-primary"
                                        (click)="this.saveRTAI(row)"><i class="fa fa-save fa-1x"></i> </button>
                                </mat-cell>
                            </ng-container>
                            <ng-container *ngIf="this.aparell.deltaCarZBMetode === enumMetodeDeltaCar.CALLE">
                                <mat-cell *matCellDef="let row">
                                    <button class="btn btn-outline-primary" (click)="this.removeRTAC(row)"><mat-icon>delete</mat-icon></button>
                                </mat-cell>
                            </ng-container>
                            <ng-container *ngIf="this.aparell.deltaCarZBMetode === enumMetodeDeltaCar.INFRACCION">
                                <mat-cell *matCellDef="let row">
                                    <button class="btn btn-outline-primary" (click)="this.removeRTAI(row)"><mat-icon>delete</mat-icon></button>
                                </mat-cell>
                            </ng-container>
                            <ng-container *ngIf="this.aparell.deltaCarZBMetode === enumMetodeDeltaCar.ZONA">
                                <mat-cell *matCellDef="let row">
                                    <button class="btn btn-outline-primary" (click)="this.removeRTAZ(row)"><mat-icon>delete</mat-icon></button>
                                </mat-cell>
                            </ng-container>
                        </ng-container>

                        <mat-header-row *matHeaderRowDef="displayedColumnsDC"></mat-header-row>
                        <mat-row *matRowDef="let row; columns: displayedColumnsDC;"></mat-row>
                    </mat-table>

                    <div
                        *ngIf="this.aparell.deltaCarTipus !== 'POL' && this.aparell.deltaCarZBMetode === this.enumMetodeDeltaCar.SECTOR">
                        <div class="alert alert-primary" role="alert"
                            *ngIf="this.aparell.sectores !== undefined && this.aparell.sectores.length===0">
                            No se ha encontrado sectores configurados. Haz click en "Añadir sector" para empezar a
                            configurarlos.
                        </div>
                        <div class="row" style="align-items: center;max-width: fit-content;margin-left: 1vh;">
                            <button class="btn btn-primary" style="display: block; margin:1vh" (click)="addSector()"
                                [disabled]="this.anadirSectorGuardat" i18n>&nbsp;Añadir sector</button>
                            <i class="fas fa-info-circle" style="color: cornflowerblue;"></i>
                            &nbsp;
                            <span i18n>Las calles que ya esten añadidas a un sector no volverán a aparecer como
                                seleccionables en los demás sectores.</span>
                        </div>

                        <div class="card sector" name="sector" *ngFor="let sector of aparell.sectores; index as i">
                            <div class="card-body">
                                <div class="row">
                                    <app-form-text-edit required id="aparells-editor-sector" class="col-6" i18n-label
                                        label="Nombre" [(value)]="sector.nombre"></app-form-text-edit>
                                    <app-form-combo-edit required class="col-6" i18n-label label="PDA" [items]="PDAs"
                                        valueField="id" textField="id" [(value)]="sector.pda">
                                    </app-form-combo-edit>
                                </div>

                                <button class="btn" style="width: 60%;" (click)="openModalCalles(sector)" i18n>
                                    <i class="fas fa-tasks">&nbsp;</i>Calles</button>

                                <!-- Acciones -->
                                <i (click)="deleteSector(i)" style="float:right;margin-left: 0.5vh; margin-top: 0.5vh;"
                                    class="cursor fa fa-trash-alt btn btn-danger btn-sm" title="Eliminar"></i>
                                <i (click)="saveSector(i)" style="float:right;margin-left: 0.5vh; margin-top: 0.5vh;"
                                    class="cursor fa fa-save btn btn-primary btn-sm" title="Guardar"></i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </mat-tab>
        <mat-tab *ngIf="secure_id !== null" i18n-label label="Alarmes">
            <div *ngIf="this.dataSourceAlarmes.data.length === 0" class="alert alert-info">
                <i class="fas fa-info-circle"></i> &nbsp;
                <span i18n>No hi ha alarmes per mostrar</span>
            </div>

            <mat-table *ngIf="this.dataSourceAlarmes.data.length!==0" #tableAlarmes [dataSource]="dataSourceAlarmes"
                aria-label="Elements" class="is-header-sticky">


                <ng-container matColumnDef="estat">
                    <mat-header-cell *matHeaderCellDef i18n>Estat</mat-header-cell>
                    <mat-cell *matCellDef="let row"><span class="badge badge-danger" [hidden]="acceptada(row)"
                            i18n>nou</span><span class="badge" [hidden]="!acceptada(row)" i18n>acceptada</span>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="text">
                    <mat-header-cell *matHeaderCellDef i18n>Text</mat-header-cell>
                    <mat-cell *matCellDef="let row">{{row.text}}</mat-cell>
                </ng-container>

                <ng-container matColumnDef="data">
                    <mat-header-cell *matHeaderCellDef i18n>Data</mat-header-cell>
                    <mat-cell *matCellDef="let row">{{row.data | longDate}}</mat-cell>
                </ng-container>

                <ng-container matColumnDef="usuari">
                    <mat-header-cell *matHeaderCellDef i18n>Usuari</mat-header-cell>
                    <mat-cell *matCellDef="let row" class="td">{{ row.usuari }}</mat-cell>
                </ng-container>
                <ng-container matColumnDef="botons">
                    <mat-header-cell *matHeaderCellDef i18n>Acció</mat-header-cell>
                    <mat-cell *matCellDef="let row"><button class="btn btn-outline-primary btn-xs"
                            [hidden]="acceptada(row)" title="Marcar com llegida" i18n-title
                            (click)="checkAlarm(row.secure_id)"><i class="fa fa-check-double"></i></button></mat-cell>
                </ng-container>


                <mat-header-row *matHeaderRowDef="displayedColumnsAlarmes"></mat-header-row>
                <mat-row *matRowDef="let row; columns: displayedColumnsAlarmes;"></mat-row>
            </mat-table>
        </mat-tab>
    </mat-tab-group>

</div>