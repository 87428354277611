import { I18n } from '@ngx-translate/i18n-polyfill';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { PagesPermissions } from '../../models/pagesPermissions';
import { Usuari } from 'app/sections/usuaris/models/usuari.model';
import { UsuarisService } from 'app/sections/usuaris/services/usuaris.service';
import { ToastrService } from 'ngx-toastr';

enum AuthorizationPage {
  None = 'None',
  Acusos = 'Acusos',
  Denuncies = 'ClassSomintec.frExpedientes',
  Propostes = 'ClassSomintec.frPropostes',
  Aparcare = 'Aparcare',
  Tramitador = 'ClassSomintec.frTramitar',
  Matricules = 'ClassSomintec.frMatriculasEspeciales',
  TipusMatricules = 'ClassSomintec.frTipoMatriculas',
  Agents = 'ClassSomintec.frAgentes',
  Aparells = 'ClassSomintec.frAparatos',
  Legislacions = 'ClassSomintec.frLegislacion',
  MotiusAnulacio = 'ClassSomintec.frMotivosAnulacion',
  MotiusDesestimar = 'SOM_POL.Aplicacion.MotiuDesestimacioGrua.frmMotiuDesestimacio',
  Siglas = 'ClassSomintec.frSiglas',
  TipusVehicles = 'ClassSomintec.frTiposVehiculo',
  Colors = 'ClassSomintec.frColors',
  Carrers = 'ClassSomintec.frCalles',
  MarquesVehicles = 'ClassSomintec.frMarcasVehiculos',
  Infraccions = 'ClassSomintec.frInfracciones',
  Paisos = 'ClassSomintec.frPaises',
  Zonas = 'ClassSomintec.frZonas',
  ImportacioDenuncies = 'ClassSomintec.frImportacioDenuncies',
  Estadistiques = 'ClassSomintec.frEstadistiques',
  ComunicacioPda = 'ClassSomintec.frComunicacioPda',
  Sincronitzacio= 'ClassSomintec.Sincro_PDA',
  AnulacioZBAuto= 'ParkEon.frAnulacionesV3',
  Tracking = 'SOM_TRACKER.Aplicacion.frmTracking',
  AlarmSistema = 'ClassSomintec.AlertesSistema',
  AlarmPDA = 'ClassSomintec.AlertesPDA',
  AnulacionsManuals = 'ClassSomintec.frAnulacionsManualsV2',
  Avisos = 'ClassSomintec.Avisos',
  Configuracio = 'ClassSomintec.Configuracio',
  DeteccioMatricules = 'ClassSomintec.DeteccioMatricules',
  Tiquets = 'ClassSomintec.Tiquets',
  Festius = 'ClassSomintec.frFestius',
  TipusTiquets = 'ClassSomintec.frTipoTicket',
  Vehicles = 'ClassSomintec.frVehicles',
  SeguretatUsuaris = 'ClassSomintec.frSeguretatUsuaris',
  SeguretatGrups = 'ClassSomintec.frSeguretatGrups',
  Persones = 'ClassSomintec.frPersones',
  ComprovacioTiquets = 'ClassSomintec.ComprovacioTiquets',
  Recaudacio = 'ClassSomintec.frRecaudacio',
  PropuestasAnulacionZB = 'ClassSomintec.frPropuestaAnulacionZB',
  NotificacionsExpedient = 'ClassSomintec.frNotificaciones',
  GruaTecnics = 'SOM_POL.Aplicacion.Tecnic.frmTecnic',
  GruaMotiusDesestimar = 'SOM_POL.Aplicacion.MotiuDesestimacioGrua.frmMotiuDesestimacio',
  GruaMotiusEntrada = 'SOM_POL.Aplicacion.MotiuEntradaGrua.frmMotiuEntrada',
  GruaAlies = 'ClassSomintec.frAliasGrua',
  // PropostesGrua = 'SOM_POL.Aplicacion.Grua.frmGrua',
  TramitadesGrua = 'SOM_POL.Aplicacion.Grua.frmGruaAcceptades',
  DipositGrua = 'SOM_POL.Aplicacion.DipositVehicle.frmDipositVehiclesGrua',
  GruaPendents = 'SOM_POL.Aplicacion.Grua.frmGrua',
  TarifesGrua = 'SOM_POL.Aplicacion.Grua.frmTarifaGrua',
  // GruaTramitades = 'SOM_POL.Aplicacion.Grua.frmGruaAcceptades',
  GenerarProposta = 'SOM_POL.Aplicacion.Grua.frmGenerarProposta',
  Tarifes = 'ClassSomintec.frTarifas',
  Reserves = 'ClassSomintec.frReservas',
  Banc = 'ClassSomintec.frBanc',
  DGT = 'ClassSomintec.frDGT',
  SenyalsVerticals = 'ClassSomintec.frSenalesVerticales',
  Tramos = 'ClassSomintec.TRAMOS',
  Maquinas = 'ClassSomintec.MAQUINAS',
  Temporada = 'ClassSomintec.Temporada',
  EscalaOcupacion = 'ClassSomintec.frEscalaOcupacion',
  TextoInformativoAparcare = 'ClassSomintec.frTextoInformatvoAparcare',
  TextoAvisoAparcare = 'ClassSomintec.frTextoAvisoAparcare',
  MotiusNoNotificacio = 'ClassSomintec.frMotivosNotif',
  IMPORTAR_MATRICULES = 'ClassSomintec.frExpedientesImportarTerceros',
  // GruaTramitades = 'SOM_POL.Aplicacion.Grua.frmGruaAcceptades',
  Automatitzacio = 'Automatitzacio',
  Tramitar = 'ClassSomintec.frTramitarManual',
  TramitarExecutiva = 'ClassSomintec.frTramitarEjecutivas',
  TipusEnviaments = 'ClassSomintec.frTiposEnvio',
  Respostes = 'ClassSomintec.frRespuestas',
  Prepropostes = 'Prepropostes',
  GestioResidents = 'GestioResidents',
  ObservacionsPDA = 'ObservacionsPDA',
  Ajuda = 'Ajuda',
  Reincidents = 'Reincidents',
  Bonos = 'Bonos',
  OperacionsMasives = 'ClassSomintec.frOpeMasivas',
  EnvioDenuncies = 'ClassSomintec.frEnvioDenuncias',
  ProcesarC60 = 'ClassSomintec.frProcesoC60',
  IncidenciesC60 = 'ClassSomintec.frIncidenciesCuadernoC60',
  VistaGrid = 'ClassSomintec.frVistaDatagrid',
  ResidentsLog = 'ClassSomintec.frResidentesLog',
  Municipis = 'ClassSomintec.frMunicipios',
  Provincies = 'ClassSomintec.frProvincias',
  Velocotats = 'ClassSomintec.frVelocidades',
  ReportsComplet = 'ClassSomintec.frReportsComplet',
  Forms = 'ClassSomintec.frForms',
  Institucions = 'ClassSomintec.frInstituciones',
  Parametres = 'ClassSomintec.frParametros',
  Envio = 'ClassSomintec.frEnvio',
  CarregarRepostes = 'ClassSomintec.frCargarRespuestas',
  Configurar = 'ClassSomintec.frCFG_BOP_TEU_Configurar',
  BOPTEU = 'ClassSomintec.frBOP_TEU',
  ConfTEU = 'ClassSomintec.frConfirmacio_TEU',
  PuntosDGT = 'ClassSomintec.frEnvioPuntosDGT2',
  ConsultaTickets = 'Som_AparcarE.Bundles.ConsultaTiquets.frConsultaTiquets',
  AparcareHoraris = 'ClassSomintec.frAparcareHorarios2',
  Procediement = 'ClassSomintec.frProcediment',
  Cobro = 'ClassSomintec.frCobro',
  Operacions = 'ClassSomintec.frOperacions',
  Ficheros = 'ClassSomintec.frFicheros',
  PushArxiuPDAXaloc = 'NotificacionsPush.Aplicacion.frObtenirArxiuPDAxaloc',
  PushArxiuXaloc = 'NotificacionsPush.Aplicacion.frObtenirArxiuPDA',
  PushNotifLog = 'NotificacionsPush.Aplicacion.frNotificacionsPushLog',
  PushGenerarXML = 'NotificacionsPush.Aplicacion.frGenerarXMLaTransf',
  EnviarPush = 'NotificacionsPush.Aplicacion.frEnviarNotificacioPush',
  Summary = 'SOM_SEC.App.Summary.frmSummary',
  EnvioNotificacions = 'ClassSomintec.frEnvioNotificaciones',
  TramitarExecutivesOlot = 'ClassSomintec.frTramitarEjecutivasOlot',
  InformesGenerals = 'ClassSomintec.InformesGenerales',
  ExecutivaOlot = 'ClassSomintec.frRevisarEjecutivasOlot',
  DeltacarHistorico = 'DeltacarHistorico',
  TextosPredefinits = 'TextosPredefinits'

}

enum AuthorizationRole {
  Administrator = 'Admin',
  User = 'User',
  ZonaBlava = 'ZonaBlava',
  Policia = 'Policia',
  Convivencia = 'Convivencia',
  Grua = 'Grua',
  Unknown = 'Unknown'
}

enum AuthorizationProfile {
  Administrator = 'Administrator',
  Superuser = 'Superuser',
  User = 'User'
}

@Injectable()
export class AuthorizationService {
  public Pages = AuthorizationPage;
  public Roles = AuthorizationRole;
  public Profiles = AuthorizationProfile;

  constructor(
    private usuariService: UsuarisService,
    private router: Router,
    private toastr: ToastrService,
    private i18n: I18n
  ) {}
  // ...
  public GetPagesPermissions(): PagesPermissions[] {
    return null;
  }

  public UserCanSeePage(page: AuthorizationPage): boolean {
    const user: Usuari = this.usuariService.getCurrentUser();
    if (user.Permissions != null) {
      if (this.CheckUserRole(AuthorizationRole.Administrator)) {
        return true;
      } else {
        const perm = user.Permissions.find(x => x.Form === page.toString() && x.Read === true);
        if (perm !== null && perm !== undefined) {
          return perm.Read;
        }
      }
    }
    return false;
  }

  public UserCanWrite(page: AuthorizationPage): boolean {
    const user: Usuari = this.usuariService.getCurrentUser();
    if (user.Permissions != null) {
      if (this.CheckUserRole(AuthorizationRole.Administrator)) {
        return true;
      } else {
        const perm = user.Permissions.find(x => x.Form === page.toString() && x.Update === true);
        if (perm !== null && perm !== undefined) {
          return perm.Update;
        }
      }
    }
    return false;
  }

  public UserCanDelete(page: AuthorizationPage): boolean {
    const user: Usuari = this.usuariService.getCurrentUser();
    if (user.Permissions != null) {
      if (this.CheckUserRole(AuthorizationRole.Administrator)) {
        return true;
      } else {
        const perm = user.Permissions.find(x => x.Form === page.toString() && x.Delete === true);
        if (perm !== null && perm !== undefined) {
          return perm.Delete;
        }
      }
    }
    return false;
  }

  public UserCanCreate(page: AuthorizationPage): boolean {
    const user: Usuari = this.usuariService.getCurrentUser();
    if (user.Permissions != null) {
      if (this.CheckUserRole(AuthorizationRole.Administrator)) {
        return true;
      } else {
        const perm = user.Permissions.find(x => x.Form === page.toString() && x.Create === true);
        if (perm !== null && perm !== undefined) {
          return perm.Create;
        }
      }
    }
    return false;
  }

  public CheckUserPermission(page: AuthorizationPage) {
    if (!this.UserCanSeePage(page)) {
      this.toastr.error(
        this.i18n('No té permisos per accedir a aquesta pàgina!')
      );
      this.router.navigate(['/']);
      return false;
    } else {
      return true;
    }
  }

  public CheckUserRole(role: AuthorizationRole): boolean {    
    const user: Usuari = this.usuariService.getCurrentUser();
    let found;
    if (user.Roles !== undefined) {
      found = user.Roles.find(x => x === role);
    }
    return found !== undefined;
  }

  public CheckUserProfile(profile: AuthorizationProfile): boolean {
    const user: Usuari = this.usuariService.getCurrentUser();
    return profile === user.Profile;
  }

  public IsSuperUser() {    
    var user: Usuari = this.usuariService.getCurrentUser();   
    debugger; 
    return user.Roles.includes(this.Roles.Administrator) || user.Profile === this.Profiles.Administrator || user.Profile === this.Profiles.Superuser;
  }
}
